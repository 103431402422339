import React, { useState, useEffect } from 'react';
import {useLocalStorage} from 'react-use';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import $ from 'jquery';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { IoMdPerson } from "react-icons/io";
import { IoCard } from "react-icons/io5";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdGTranslate } from "react-icons/md";
import { API1 } from '../constants/api';
import { API2 } from '../constants/api';
import { encodedCredentials50 } from '../constants/credentials';
import { encodedCredentials51 } from '../constants/credentials';

function Paymentdata() {
  const [sidebarToggled, setSidebarToggled] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [user1, setUser1] = useLocalStorage("user");
  const [bank, setBank] = useState("");
  const [clabe, setClabe] = useState("");
  const fechaActual = new Date();
  // const [validate, setValidate] = useState(0);
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");

  const [userName, setUserName] = useState([]);

  const [paymentdata, setPaymentdata] = useState([]);

  const [isLoading, setIsLoading] = useState(false); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); 

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || i18n.language 
  );

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
    i18n.changeLanguage(selectedLanguage); 
  }, [selectedLanguage]); 

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setIsButtonDisabled(true);

              const data2 = {
                "user": user,
                "pass": pass,
            };

            axios.post(`${API1}login`, data2, {
              headers: {
                'Authorization': `Basic ${encodedCredentials50}`,
                'Content-Type': 'application/json'
              }
            })
            .then((response) => {
              if (response.data.message === "Successful login.") {
                const data = {
                  "user": user1,
                  "bank": bank,
                  "clabePaymentMethod": clabe,
                  "createDate": fechaActual,
                  "userModify": "pagina web",
                  "validate": 1
                };
                {
                   axios.post(`${API2}paydata`, data, {
                    headers: {
                      'Authorization': `Basic ${encodedCredentials51}`,
                      'Content-Type': 'application/json'
                    }
                  })
                  .then((response) => {
                    if(response){ 
                      Swal.fire({
                        title: selectedLanguage === 'en' ? 'Success' : 'Éxito',
                        text:  selectedLanguage === 'en' ? 'The operation was successful.': "La operación fue exitosa",
                        icon: 'success',
                        confirmButtonText: 'OK'
                      }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                      }
                    })
                    .catch((error) => {
                      console.error('Error:', error.message);
                      Swal.fire({
                        title: 'Error',
                        text: error.message || 'There was an error processing the request.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                      });
                    });
                };

                setIsLoading(false);
              setIsButtonDisabled(false);

                  // Borra la información de los inputs
                      setUser('');
                      setPass('');
                      setBank('');
                      setClabe('');

                      $('#logoutModal2').modal('hide');

                      $('#modal2').modal('hide');

                Swal.fire({
                  title: 'Success',
                  text: 'The operation was successful.',
                  icon: 'success',
                  confirmButtonText: 'OK'
                });
              }
            })
            .catch((error) => {
              if (error.error === "Invalid username or password.") {
                Swal.fire({
                title: 'Error',
                text: error.error || 'Invalid username or password.',
                icon: 'error',
                confirmButtonText: 'OK'
                });
              }
              if (error.error === "Internal error. Contact support.") {
                console.error('Error:', error.response.data.error);
                Swal.fire({
                  title: 'Error',
                  text: error.error || 'There was an error processing the request.',
                  icon: 'error',
                  confirmButtonText: 'OK'
                });
              }
              setIsLoading(false);
              setIsButtonDisabled(false);
            });
              }

                  const formData = {
                    "user": user1
                  };

                  const response = async () => {
                    await axios.post(`${API1}paymentdata`, formData, {
                      headers: {
                        'Authorization': `Basic ${encodedCredentials50}`
                      }
                    })
                    .then((response) => {
                      
                    const data = response.data
                    setPaymentdata(data.paymentdata);
                    setUserName(data.name);
                    })
                  }
                  useEffect(() => {
                    response();
                    
                  }, []);
                  
                  
                  const columns = [
                    {
                      name: "bank",
                      label: selectedLanguage === 'en' ? 'Bank' : 'Banco',
                    },
                    {
                      name: "clabe_payment_method",
                      label: selectedLanguage === 'en' ? "Interbank Account Number" : 'Número de cuenta', 
                    },
                    {
                      name: "create_date",
                      label:  selectedLanguage === 'en' ? 'Date' : 'Fecha'
                    },
 
                  ];

                  const handleBankChange = (event) => {
                    const value = event.target.value;
                    const regex = /^[a-zA-Z0-9\s]*$/; // Expresión regular para letras y números
                    if (regex.test(value)) {
                      setBank(value);
                    }
                  };
                  const handleSidebarToggle = () => {
                    setSidebarToggled(!sidebarToggled);
                  };
 
                  const handleLogout = () => {
                    localStorage.clear();
                    sessionStorage.clear();
                  }                
  return (
    <div>
        {/* Page Wrapper */}
        <div id="wrapper">
    {/* Sidebar */}
    <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion d-flex ${sidebarToggled ? 'toggled' : ''}`} id="accordionSidebar">
      {/* Sidebar - Brand */}
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#index">
        <div className="sidebar-brand-icon">
          {/* <i className="fas fa-laugh-wink" px-3 px-sm-4 mt-3 mb-4 style={{width: '25rem'}}/> */}
          <img className="img-fluid px-1 px-sm-2 mt-1 mb-2 " style={{width: '28rem'}} src="img/iconoblanco.png" alt="logo" />
        </div>
        <div className="sidebar-brand-text mx-3">México lindo y querido</div>
      </a>
      {/* Divider */}
      <hr className="sidebar-divider my-0" />
      {/* Nav Item - Dashboard */}
      <li className="nav-item active">
        <a className="nav-link" href="#index">
          <i className="fas fa-fw fa-tachometer-alt" />
          <span>{t("navbar.dashboard")}</span></a>
      </li>
      {/* Divider */}
      <hr className="sidebar-divider" />

<li className="nav-item">
<a className="nav-link" href="#profile">
    <IoMdPerson />
    <span>{t("navbar.profile")}</span></a>
</li>

<hr className="sidebar-divider" />
{/* Heading */}

{/* Nav Item - Pages Collapse Menu */}
<li className="nav-item">
<a className="nav-link" href="#payment-data">
<IoCard />
    <span>{t("navbar.payment-data")}</span></a>
</li>
{/* Nav Item - KYC */}
<li className="nav-item">
  <a className="nav-link" href="#kyc">
  <IoDocumentTextOutline />
    <span>{t("navbar.kyc")}</span></a>
</li>

{/* Divider */}
<hr className="sidebar-divider d-none d-md-block" />
{/* Sidebar Toggler (Sidebar) */}
<div className="text-center d-none d-md-flex justify-content-center"> {/* comentar aqui d-none d-md-inline*/}
<button
  className="rounded-circle border-0"
  id="sidebarToggle"
  onClick={handleSidebarToggle}
></button>
</div>

</ul>

{/* End of Sidebar */}

{/* Content Wrapper */}
<div id="content-wrapper" className="d-flex flex-column">
{/* Main Content */}
<div id="content">
  {/* Topbar */}
  <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    {/* Sidebar Toggle (Topbar) */}
    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={handleSidebarToggle}>
      <i className="fa fa-bars" />
    </button>
    <ul className="navbar-nav ml-auto">

      {/* TRADUCCION*/}
      <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="translate" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <MdGTranslate />
              </a>
              {/* Dropdown - Alerts */}
              {/* <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in " aria-labelledby="alertsDropdown"> */}
              <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                      <div className="d-sm-block">
                          <button className="dropdown-item btn btn-sm" onClick={() => handleLanguageChange("es")}>Español</button>
                      </div>
                      <div className="d-sm-block">
                          <button className="dropdown-item btn btn-sm" onClick={() => handleLanguageChange("en")}>English</button>
                      </div>
              </div>
          </li>
            {/* Nav Item - Alerts */}
            <li className="nav-item dropdown no-arrow mx-1">
              <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-bell fa-fw" />
                {/* Counter - Alerts */}
                {/* <span className="badge badge-danger badge-counter">3+</span> */}
              </a>
              {/* Dropdown - Alerts */}
              {/* <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                <h6 className="dropdown-header">
                  Alerts Center
                </h6>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="mr-3">
                    <div className="icon-circle bg-primary">
                      <i className="fas fa-file-alt text-white" />
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">December 12, 2019</div>
                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="mr-3">
                    <div className="icon-circle bg-success">
                      <i className="fas fa-donate text-white" />
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">December 7, 2019</div>
                    $290.29 has been deposited into your account!
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="mr-3">
                    <div className="icon-circle bg-warning">
                      <i className="fas fa-exclamation-triangle text-white" />
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">December 2, 2019</div>
                    Spending Alert: We've noticed unusually high spending for your account.
                  </div>
                </a>
                <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
              </div> */}
            </li>
            {/* Nav Item - Messages */}
            <li className="nav-item dropdown no-arrow mx-1">
              <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-envelope fa-fw" />
                {/* Counter - Messages */}
                {/* <span className="badge badge-danger badge-counter">7</span> */}
              </a>
              {/* Dropdown - Messages */}
            </li>
            <div className="topbar-divider d-none d-sm-block" />
            {/* Nav Item - User Information */}
            <li className="nav-item dropdown no-arrow">
              <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="mr-2 d-none d-lg-inline text-gray-600 small">{[userName]}</span>
                <img className="img-profile rounded-circle" src="img/undraw_profile.svg" />
              </a>
              {/* Dropdown - User Information */}
              <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <a className="dropdown-item" href="#profile">
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                  {t("navbar.profile")}
                </a>
                <a className="dropdown-item" href="#settings">
                  <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                  {t("navbar.settings")}
                </a>
                <div className="dropdown-divider" />
                <button className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                  {t("navbar.logout")}
                </button>
              </div>
            </li>
          </ul>
        </nav>
          {/* End of Topbar */}
          {/* Begin Page Content */}
          
          <div className="row">
            {/* Area Chart */}
            <div className="col-xl-12 col-lg-5">
              <div className="card shadow mb-4">
                {/* Card Header - Dropdown */}
                
                  <div className="card-header py-3 d-flex justify-content-between align-items-center">
                    <h2 className="h3 mb-0 text-gray-800">{t("navbar.payment-data")}:</h2>
                      <a   data-toggle="modal" data-target="#logoutModal2" className=" d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                        <i className="fas fa-download fa-sm text-white-50" />{t("payment.newpayment")}</a>
                </div>
                    <div className="card-body ">
                      
                    <MUIDataTable
                        data={paymentdata}
                        columns={columns} 
                      />

                    </div>
              </div>
            </div>
          </div>
        
        {/* End of Main Content */}
        {/* Footer */}
        <footer className="sticky-footer bg-white">
          <div className="container my-auto">
            <div className="copyright text-center my-auto">
              <span>Copyright © Your Website 2024</span>
            </div>
          </div>
        </footer>
        {/* End of Footer */}
      </div>
      {/* End of Content Wrapper */}
    </div>
    {/* End of Page Wrapper */}
    {/* Scroll to Top Button*/}
    <a className="scroll-to-top rounded" href="#page-top">
      <i className="fas fa-angle-up" />
    </a>
    <div className="modal fade" id="logoutModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">{t("footer.ready")}</h5>
          <button className="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">{t("footer.select")}</div>
        <div className="modal-footer">
          <button className="btn btn-secondary" type="button" data-dismiss="modal">{t("footer.cancel")}</button>
          <a className="btn btn-primary" onClick={handleLogout} href="login">{t("navbar.logout")}</a>
        </div>
      </div>
    </div>
  </div>
  
    <div className="modal fade" id="logoutModal2" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog " role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{t("navbar.payment-data")}</h5>
            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <h6>{t("payment.bank")}:</h6>
            <input type="text" className="text-uppercase" style={{ width: '300px' }} value={bank} onChange={handleBankChange}></input>
            <br/>
            <h6>{t("payment.interbank")}:</h6>
            <input type="text" style={{ width: '300px' }} value={clabe} onChange={(e) => setClabe(e.target.value)}></input>
          </div>
          <div className="modal-footer">
            <button className="btn btn-sm btn-secondary" type="button" data-dismiss="modal">{t("footer.cancel")}</button>
            <button className="btn btn-sm btn-primary" data-toggle="modal" data-target="#modal2"  >{t("payment.submit")}</button>
          </div>
        </div>
      </div>
    </div>

    <div className="modal fade" id="modal2" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-sm" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">{t("payment.please")}</h5>
          <button className="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
        <input type="text"
                     className="form-control form-control-user"
                      id="exampleInputEmail" aria-describedby="emailHelp" 
                      placeholder="User..."
                      value={user} onChange={(e) => setUser(e.target.value)} />
                      <br/>
         <input type="password"
                     className="form-control form-control-user"
                     id="exampleInputPassword"
                     placeholder="Password" 
                     value={pass} onChange={(e) => setPass(e.target.value)}/>
        </div>
        <div className="modal-footer">
          <button className="btn btn-secondary" type="button" data-dismiss="modal">{t("footer.cancel")}</button>
          <a className="btn btn-primary" href="#login" disabled={isButtonDisabled} onClick={handleSubmit}>{isLoading ? 'Loading...' : 'Accept'} </a>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
  );
};

export default Paymentdata;