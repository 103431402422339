import React, { useState, useEffect } from 'react';
import {useLocalStorage} from 'react-use';
import { useTranslation } from 'react-i18next';
import './Register.module.css';
import { IoMdPerson } from "react-icons/io";
import { IoCard } from "react-icons/io5";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdGTranslate } from "react-icons/md";

function Settings() {
  const [sidebarToggled, setSidebarToggled] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [collapsed, setCollapsed] = useState(false);
  const [user, setUser] = useLocalStorage("user");
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || i18n.language // Get initial language
  );

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
    i18n.changeLanguage(selectedLanguage); // Update i18n library
  }, [selectedLanguage]); // Update on language change

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
  }
  const handleSidebarToggle = () => {
    setSidebarToggled(!sidebarToggled);
  };
  return (
    <div>
  <div id="wrapper">
    {/* Sidebar */}
    <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion d-flex ${sidebarToggled ? 'toggled' : ''}`} id="accordionSidebar">
      {/* Sidebar - Brand */}
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#index">
        <div className="sidebar-brand-icon">
          {/* <i className="fas fa-laugh-wink" px-3 px-sm-4 mt-3 mb-4 style={{width: '25rem'}}/> */}
          <img className="img-fluid px-1 px-sm-2 mt-1 mb-2 " style={{width: '28rem'}} src="img/iconoblanco.png" alt="logo" />
        </div>
        <div className="sidebar-brand-text mx-3">México lindo y querido</div>
      </a>
      {/* Divider */}
      <hr className="sidebar-divider my-0" />
      {/* Nav Item - Dashboard */}
      <li className="nav-item active">
        <a className="nav-link" href="#index">
          <i className="fas fa-fw fa-tachometer-alt" />
          <span>{t("navbar.dashboard")}</span></a>
      </li>
      {/* Divider */}
      <hr className="sidebar-divider" />

<li className="nav-item">
<a className="nav-link" href="#profile">
    <IoMdPerson />
    <span>{t("navbar.profile")}</span></a>
</li>

<hr className="sidebar-divider" />
{/* Heading */}

{/* Nav Item - Pages Collapse Menu */}
<li className="nav-item">
<a className="nav-link" href="#payment-data">
<IoCard />
    <span>{t("navbar.payment-data")}</span></a>
</li>
{/* Nav Item - KYC */}
<li className="nav-item">
  <a className="nav-link" href="#kyc">
  <IoDocumentTextOutline />
    <span>{t("navbar.kyc")}</span></a>
</li>

{/* Divider */}
<hr className="sidebar-divider d-none d-md-block" />
{/* Sidebar Toggler (Sidebar) */}
<div className="text-center d-none d-md-flex justify-content-center"> {/* comentar aqui d-none d-md-inline*/}
<button
  className="rounded-circle border-0"
  id="sidebarToggle"
  onClick={handleSidebarToggle}
></button>
</div>

</ul>

{/* End of Sidebar */}

{/* Content Wrapper */}
<div id="content-wrapper" className="d-flex flex-column">
{/* Main Content */}
<div id="content">
  {/* Topbar */}
  <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    {/* Sidebar Toggle (Topbar) */}
    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={handleSidebarToggle}>
      <i className="fa fa-bars" />
    </button>
    <ul className="navbar-nav ml-auto">

      {/* TRADUCCION*/}
      <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href='#' id="translate" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <MdGTranslate />
              </a>
                    {/* Dropdown - Alerts */}
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <div className="d-sm-block">
                                <button className="dropdown-item  btn btn-sm" onClick={() => handleLanguageChange("es")}>Español</button>
                            </div>
                            <div className="d-sm-block">
                                <button className="dropdown-item btn btn-sm" onClick={() => handleLanguageChange("en")}>English</button>
                            </div>
                    </div>
                </li>
            
            {/* Nav Item - Alerts */}

            <li className="nav-item dropdown no-arrow mx-1">
              <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-bell fa-fw" />
                {/* Counter - Alerts */}
                {/* <span className="badge badge-danger badge-counter">3+</span> */}
              </a>
              {/* Dropdown - Alerts */}
              {/* <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                <h6 className="dropdown-header">
                  Alerts Center
                </h6>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="mr-3">
                    <div className="icon-circle bg-primary">
                      <i className="fas fa-file-alt text-white" />
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">December 12, 2019</div>
                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="mr-3">
                    <div className="icon-circle bg-success">
                      <i className="fas fa-donate text-white" />
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">December 7, 2019</div>
                    $290.29 has been deposited into your account!
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="mr-3">
                    <div className="icon-circle bg-warning">
                      <i className="fas fa-exclamation-triangle text-white" />
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">December 2, 2019</div>
                    Spending Alert: We've noticed unusually high spending for your account.
                  </div>
                </a>
                <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
              </div> */}
            </li>
            {/* Nav Item - Messages */}
            <li className="nav-item dropdown no-arrow mx-1">
              <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-envelope fa-fw" />
                {/* Counter - Messages */}
                {/* <span className="badge badge-danger badge-counter">7</span> */}
              </a>
              {/* Dropdown - Messages */}
            </li>
            <div className="topbar-divider d-none d-sm-block" />
            {/* Nav Item - User Information */}
            <li className="nav-item dropdown no-arrow">
              <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="mr-2 d-none d-lg-inline text-gray-600 small">{[user]}</span>
                <img className="img-profile rounded-circle" src="img/undraw_profile.svg" />
              </a>
              {/* Dropdown - User Information */}
              <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <a className="dropdown-item" href="#profile">
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                  {t("navbar.profile")}
                </a>
                <a className="dropdown-item" href="#settings">
                  <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                  {t("navbar.settings")}
                </a>
                <div className="dropdown-divider" />
                <button className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                  {t("navbar.logout")}
                </button>
              </div>
            </li>
          </ul>
        </nav>
        {/* End of Topbar */}
        {/* Begin Page Content */}
        <div className="container-fluid">
          {/* Page Heading */}
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Cambio de contraseña</h1>
          </div>
          {/* Content Row */}
          
          <div className="row">
            <div className='col'>
                <form>
                  <label>
                    Contraseña actual:
                  </label>
                  <input type='text' className="form-control">
                  </input>
                  <label>
                    Nueva contraseña:
                  </label>
                  <input  type='text' className="form-control">
                  </input>
                  <label>
                    Repetir nueva contraseña:
                  </label>
                  <input  type='text' className="form-control">
                  </input>
                </form>
            </div>

          </div>

                </div>
              
      {/* Footer */}
      <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto">
            <span>Copyright © Your Website 2024</span>
          </div>
        </div>
      </footer>
      {/* End of Footer */}
    </div>
    {/* End of Content Wrapper */}
  </div>
  {/* End of Page Wrapper */}
  </div>
  {/* Scroll to Top Button*/}
  <a className="scroll-to-top rounded" href="#page-top">
    <i className="fas fa-angle-up" />
  </a>
  {/* Logout Modal*/}
  <div className="modal fade" id="logoutModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">{t("footer.ready")}</h5>
          <button className="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">{t("footer.select")}</div>
        <div className="modal-footer">
          <button className="btn btn-secondary" type="button" data-dismiss="modal">{t("footer.cancel")}</button>
          <a className="btn btn-primary" onClick={handleLogout} href="login">{t("navbar.logout")}</a>
        </div>
      </div>
    </div>
  </div>
</div>
    
  );
};

export default Settings;