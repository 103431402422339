import React, { useState, useEffect } from 'react';
import Tabledavid from './Tabledavid.js';
import Swal from 'sweetalert2';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {useLocalStorage} from 'react-use';
import MUIDataTable from "mui-datatables";
import { useTranslation } from 'react-i18next';
import styles from "../Register.module.css";
import { IoMdPerson } from "react-icons/io";
import { IoCard } from "react-icons/io5";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdGTranslate } from "react-icons/md";
import { API2 } from '../../constants/api.js';
import { encodedCredentials51 } from '../../constants/credentials.js';
import { useApiDataContext } from '../../constants/apiContext.js';
import IconRegister from '../../Iconos/IconRegister.js';
// import IconRegister2 from '../../Iconos/IconRegister2.js';

function Body () {
  const [t, i18n] = useTranslation("global");
  const [user, setUser] = useLocalStorage("user");
  const { wallet, investmentTotal, earningsTotal, withdrawalsTotal, withdrawals, invesment, payment, userName, divisa} = useApiDataContext();
  const [withdrawal2, setWithdrawal2] = useState("");
  const [create, setCreate] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [investmentDate, setInvestmentDate] = useState([]);
  const fechaActual = new Date();
  const [withdrawal, setWithdrawal] = useState("");
  const [sidebarToggled, setSidebarToggled] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || i18n.language 
  );

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
    i18n.changeLanguage(selectedLanguage); 
  }, [selectedLanguage]); 

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();

    // Navigate('login')
  }

  useEffect(() => {
    const dates = invesment.map(item => item.investment_start);
    setInvestmentDate(dates);
  }, [invesment]);

  useEffect(() => {
  }, [investmentDate]);
  
  const columnsWithdrawal = [
    {
      name: "withdrawal",
      label: selectedLanguage === 'en' ? 'Amount' : 'Monto'
    },
    {
      name: "withdrawal_date",
      label: selectedLanguage === 'en' ? 'Date' : 'Fecha'
    },
    {
      name: "create_date",
      label: selectedLanguage === 'en' ? 'Create date' : 'Fecha de creación'
    },
    {
      name: "bank_clabe",
      label: selectedLanguage === 'en' ? 'Bank' : 'Banco'
    },
    {
      name: "status",
      label: selectedLanguage === 'en' ? 'status' : 'Estatus'
    },

  ];
  const columnsInvestment = [
    
    {
      name: "financial_investment",
      label: selectedLanguage === 'en' ? 'investment' : 'inversión'
    },
    {
      name: "investment_time",
      label: selectedLanguage === 'en' ? 'investment period' : 'Tiempo de inversión'
    },
    {
      name: "withdrawals_time",
      label: selectedLanguage === 'en' ? 'payout time' : 'tiempo de retiros'
    },
    {
      name: "investment_start",
      label: selectedLanguage === 'en' ? 'investment start' : 'fecha de inicio'
    },
    {
      name: "create_date",
      label: selectedLanguage === 'en' ? 'Create date' : 'Fecha de creación'
    },
    {
      name: "status",
      label: selectedLanguage === 'en' ? 'status' : 'Estatus'
    } 
 
  ];
  

  const optionsInvestment = {
    selectableRows: 'none',
    responsive: 'standard',

    setTableProps: () => ({
      style: {
        backgroundColor: "#D4F2EC", 
        border: "1px solid #ddd" 
      }
    })
  };
  const optionsWithdrawals = {
    responsive: 'standard',
    selectableRows: 'none',

    setTableProps: () => ({
      style: {
        backgroundColor: "#E0FCD9", 
        border: "1px solid #ddd",
        width: "100%", 
        margin: "0%", 
      }
    })
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();


      if (withdrawal > wallet) {
        Swal.fire({
            title: 'Error',
            text: 'The withdrawal amount cannot exceed the wallet balance.',
            icon: 'error',
            confirmButtonText: 'OK'
        });
        return; 
    }
    const data = {
        "user": user,
        "divisa" : divisa,
        "withdrawal": withdrawal,                        
        "withdrawal_date": fechaActual,
        "create_date": fechaActual,
        "user_modify": "pagina web",
        "bank_clabe" : selectedBank
    };
    fetch(`${API2}withdraw`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${encodedCredentials51}`,
           'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.error || 'Error desconocido');
          });
        }
        return response.json();
      })
      .then((response) => {
        if(response.message){
        Swal.fire({
          title: 'Success',
          text:  selectedLanguage === 'en' ? 'The operation was successful.': "La operación fue exitosa",
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
              window.location.reload();
          }
      });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        Swal.fire({
          title: 'Error',
          text: error.error || selectedLanguage === 'en' ? 'Select a date within the 5 allowed days of your investment.' : 'Seleccione una fecha dentro de los primeros 5 días permitidos de su inversión.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        .then((result) => {
          if (result.isConfirmed) {
              window.location.reload();
          }
      });
      });
  }

  const handleSubmit2 = (e) => {
    e.preventDefault();

    
  if (withdrawal > wallet) {
    Swal.fire({
      title: 'Error',
      text: 'The withdrawal amount cannot exceed the wallet balance.',
      icon: 'error',
      confirmButtonText: 'OK'
    });
    return;
    
  }
        const data2 = {
          "user": user,
          "withdrawal": withdrawal2,
          "divisa": divisa, 
          "withdrawal_date": create,
          "create_date": fechaActual,
          "user_modify": "pagina web",
          "bank_clabe" : selectedBank
      };
      fetch(`${API2}withdraw`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${encodedCredentials51}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data2)
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.error || 'Error desconocido');
          });
        }
        return response.json();
      })
      .then((response) => {
        if(response.message){
        Swal.fire({
          title: 'Success',
          text:  selectedLanguage === 'en' ? 'The operation was successful.': "La operación fue exitosa",
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
              window.location.reload();
          }
      });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        Swal.fire({
          title: 'Error',
          text: error.error || selectedLanguage === 'en' ? 'Select a date within the 5 allowed days of your investment.' : 'Seleccione una fecha dentro de los primeros 5 días permitidos de su inversión.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        .then((result) => {
          if (result.isConfirmed) {
              window.location.reload();
          }
      });
      });
      
      

  }

      const handleSidebarToggle = () => {
        setSidebarToggled(!sidebarToggled);
      };
    
      

  return (
 <div>
  {/* Page Wrapper */}
  <div id="wrapper">
    {/* Sidebar */}
    <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion d-flex ${sidebarToggled ? 'toggled' : ''}`} id="accordionSidebar">
      {/* Sidebar - Brand */}
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#index">
        <div className="sidebar-brand-icon">
          {/* <i className="fas fa-laugh-wink" px-3 px-sm-4 mt-3 mb-4 style={{width: '25rem'}}/> */}
          <img className="img-fluid px-1 px-sm-2 mt-1 mb-2 " style={{width: '28rem'}} src="img/iconoblanco.png" alt="logo" />
        </div>
        <div className="sidebar-brand-text mx-3">México lindo y querido</div>
      </a>
      {/* Divider */}
      <hr className="sidebar-divider my-0" />
      {/* Nav Item - Dashboard */}
      <li className="nav-item active">
        <a className="nav-link" href="#index">
          <i className="fas fa-fw fa-tachometer-alt" />
          <span>{t("navbar.dashboard")}</span></a>
      </li>
      {/* Divider */}
      <hr className="sidebar-divider" />

      <li className="nav-item">
      <a className="nav-link" href="#profile">
          <IoMdPerson />
          <span>{t("navbar.profile")}</span></a>
      </li>

      <hr className="sidebar-divider" />
      {/* Heading */}

      {/* Nav Item - Pages Collapse Menu */}
      <li className="nav-item">
      <a className="nav-link" href="#payment-data">
      <IoCard />
          <span>{t("navbar.payment-data")}</span></a>
      </li>
      {/* Nav Item - KYC */}
      <li className="nav-item">
        <a className="nav-link" href="#kyc">
        <IoDocumentTextOutline />
          <span>{t("navbar.kyc")}</span></a>
      </li>

      {/* Divider */}
      <hr className="sidebar-divider d-none d-md-block" />
      {/* Sidebar Toggler (Sidebar) */}
      <div className="text-center d-none d-md-flex justify-content-center"> {/* comentar aqui d-none d-md-inline*/}
      <button
        className="rounded-circle border-0"
        id="sidebarToggle"
        onClick={handleSidebarToggle}
      ></button>
      </div>

    </ul>
    {/* End of Sidebar */}

    {/* Content Wrapper */}
    <div id="content-wrapper" className="d-flex flex-column">
      {/* Main Content */}
      <div id="content">
        {/* Topbar */}
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          {/* Sidebar Toggle (Topbar) */}
          <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={handleSidebarToggle}>
            <i className="fa fa-bars" />
          </button>
          <ul className="navbar-nav ml-auto">

            {/* TRADUCCION*/}
            <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="translate" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <MdGTranslate />
                    </a>
                    {/* Dropdown - Alerts */}
                    {/* <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in " aria-labelledby="alertsDropdown"> */}
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <div className="d-sm-block">
                                <button className=" dropdown-item btn btn-sm" onClick={() => handleLanguageChange("es")}>Español</button>
                            </div>
                            <div className="d-sm-block">
                                <button className="dropdown-item  btn btn-sm" onClick={() => handleLanguageChange("en")}>English</button>
                            </div>
                    </div>
                </li>

            {/* Nav Item - Alerts */}
           
            <li className="nav-item dropdown no-arrow mx-1">
              <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-bell fa-fw" />
                {/* Counter - Alerts */}
                {/* <span className="badge badge-danger badge-counter">3+</span> */}
              </a>
            </li>
            {/* Nav Item - Messages */}
            <li className="nav-item dropdown no-arrow mx-1">
              <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-envelope fa-fw" />
                {/* Counter - Messages */}
                {/* <span className="badge badge-danger badge-counter">7</span> */}
              </a>
              {/* Dropdown - Messages */}
            </li>
            <div className="topbar-divider d-none d-sm-block" />
            {/* Nav Item - User Information */}
            <li className="nav-item dropdown no-arrow">
              <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="mr-2 d-none d-lg-inline text-gray-600 small">{[userName]}</span>
                <img className="img-profile rounded-circle" src="img/undraw_profile.svg" />
              </a>
              {/* Dropdown - User Information */}
              <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <a className="dropdown-item" href="#profile">
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                  {t("navbar.profile")}
                </a>
                <a className="dropdown-item" href="#settings">
                  <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                  {t("navbar.settings")}
                </a>
                <div className="dropdown-divider" />
                <button className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                  {t("navbar.logout")}
                </button>
              </div>
            </li>
          </ul>
        </nav>
        {/* End of Topbar */}
        {/* Begin Page Content */}
        <div className="container-fluid">
          {/* Page Heading */}
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">{t("header.welcome")} {[userName]}</h1>
            {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-download fa-sm text-white-50" /> Generate Report</a> */}
          </div>
          {/* Content Row */}
          <div className="row">
            {/* Earnings (Monthly) Card Example */}
            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      {t("header.investment")}</div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">${[investmentTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]}
                       {(" ")}  {[divisa]}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-calendar fa-2x text-gray-300" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Earnings (Monthly) Card Example */}
            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-success shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      {t("header.earnings")}</div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">${[earningsTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]}
                      {(" ")}  {[divisa]}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Earnings (Monthly) Card Example */}
            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-info shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                      {t("header.total-withdrawals")}
                      </div>
                      <div className="row no-gutters align-items-center">
                        <div className="col-auto">
                          <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">${[withdrawalsTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]}
                          {(" ")}  {[divisa]}
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-auto">
                    <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Pending Requests Card Example */}
            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-warning shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                      {t("header.available")}</div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        ${[wallet.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]}
                        {(" ")}  {[divisa]}</div>
                    </div>
                    <div className="col-auto">
                    <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container"> */}
          <div className="row">
            {/* Content Column */}
            <div className="col-lg-6 mb-4">
              {/* Project Card Example */}
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">{t("header.new-investment")}</h6>
                </div>
                <div
                    className="card-body overflow-auto"
                    style={{
                      height: window.innerWidth >= 400 ? '370px' : 'auto',
                    }}
                  >
                    <Tabledavid/>
                  </div>
              </div>
              </div>
              <div className="col-lg-6 mb-4">
              <div className="card shadow mb-4 pb-4">
              {/* <div className="card-body flex-row justify-content-start" style={{ height: '388px' }}> */}
              <div className="card-body flex-row justify-content-start" >
              {/* <div className="card-body d-flex flex-row align-items-center justify-content-between" style={{ height: '388px' }}> */}
                <div className="text-center">
                  <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example" className="font-weight-bold text-primary">
                    <Tab eventKey="home" title={<span style={{ fontWeight: 'bold', color: 'rgb(0, 123, 255)' }}>{t("header.withdrawals")}</span>}>
                      {/* <Withdrawals1/> */}
                      <div className="font-weight-bold text-gray-700">
                          <form className="user" onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-5">
                                <img className="img-fluid px-2 px-sm-4 mt-3" style={{width: '10rem', height: '9rem'}} src="img/test.PNG" alt="logo"/>
                              </div>
                              <div className="col-7">
                                <br/>
                                <h5 className="font-weight-bold text-gray-800">{t("header.available")}<br/>
                                ${[wallet.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]}</h5>
                                
                                <br/>
                                <h6 className="font-weight-bold text-gray-800 ">{t("header.withdrawals")} $
                                <IconRegister
                                content={t("header.inputWithdrawals")}/>
                                </h6>
                                <input type="number" id='input2' className="form-control" value={withdrawal} onChange={(e) => setWithdrawal(e.target.value)} max={[wallet]} ></input>
                              </div>
                                  </div>
                                  <br/>
                                  <div className="row">
                                  <div className="col-12">
                                  <h6 className="font-weight-bold text-gray-800">{t("header.bank-interbank")}
                                  <IconRegister
                                  content={t("header.inputBank")}/>
                                  </h6>
                                  <select name="bank" required id="bank1" className="form-control" value={selectedBank} onChange={(e) => setSelectedBank(e.target.value)}>
                                  <option value="" disabled>{t("header.select")}</option>
                                    {payment && payment.length > 0 ? (
                                      payment.map((paymentItem, index) => (
                                        <option key={index} value={paymentItem.bank_clabe}>{paymentItem.bank_clabe}</option>
                                      ))
                                    ) : (
                                      <option value="">No payment options available</option>
                                    )}
                                  </select>
                                  </div>
                                  </div>
                                <br/>
                                <div className="col">
                                  <button type="submit" className="btn btn-sm btn-primary shadow-sm float-right">{t("header.accept")}</button>
                                </div>
                                </form>
                        </div>
                    </Tab>
                    <Tab eventKey="profile" title={<span style={{ fontWeight: 'bold', color: 'rgb(0, 123, 255)' }}>{t("header.scheduled-withdrawal")}</span>}>
                    <div className="font-weight-bold text-gray-700">
      <form className="user" onSubmit={handleSubmit2}>
      {/* <form className="user"> */}
        <div className="row">
          <div className="col-5">
            <img className="img-fluid px-2 px-sm-4 mt-3" style={{width: '10rem', height: '9rem'}} src="img/test.PNG" alt="logo"/>
          </div>
          
          <div className="col-7">
          <h5 className="font-weight-bold text-gray-800">{t("header.available")}<br/>
          ${[wallet.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]}</h5>
            
            <h6 className="font-weight-bold text-gray-800">{t("header.withdrawals")}$
            <IconRegister
            content={t("header.inputWithdrawals2")}/>
            </h6>
            <input type="number" id='input4' className="form-control" value={withdrawal2} onChange={(e) => setWithdrawal2(e.target.value)} max={[wallet]}></input>
            
            <label htmlFor="fecha">{t("header.Choose")}: 
            <IconRegister
            content={t("header.inputChoose")}/>
            </label>
            {/* <input type="date" className="form-control" id="fecha" name="fecha" min={minDate} required value={create} onChange={(e) => setCreate(e.target.value)} /> */}
            <input type="date" className="form-control" id="fecha" name="fecha" required value={create} onChange={(e) => setCreate(e.target.value)} />
          </div>
        </div>
        <div className="row">
          
            <div className="col-12">
              <h6 className="font-weight-bold text-gray-800">{t("header.bank-interbank")}
              <IconRegister
              content={t("header.inputBank")}/>
              </h6>
            <select name="bank" required id="bank1" className="form-control" value={selectedBank} onChange={(e) => setSelectedBank(e.target.value)}>
            <option value="" disabled>{t("header.select")}</option>
            {payment && payment.length > 0 ? (
              payment.map((paymentItem, index) => (
                <option key={index} value={paymentItem.bank_clabe}>{paymentItem.bank_clabe}</option>
              ))
            ) : (
              <option value="">No payment options available</option>
            )}
          </select>
          </div>
          </div>
       <br/>
      <div className="col">
        <button type="submit" className="btn btn-sm btn-primary shadow-sm float-right">{t("header.accept")}</button>
      </div>
      </form>
    </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              {/* </div> */}
          </div>
          {/* Content Row */}
          <div className="row">
          <div className={styles.miniatureTableContainer}>
            {/* Area Chart */}
            {/* <div className="col-xl-12 col-lg-1"> */}
            <div className="col-xl-12 col-md-12 col-lg-12">
              {/* <div className="card shadow mb-4"> */}
              <div className="card shadow mb-4">
                {/* Card Header - Dropdown */}
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">{t("header.investment")}</h6>

                </div>
                {/* Card Body */}
                <div>
                    <MUIDataTable
                        data={invesment}
                        columns={columnsInvestment} 
                        options={optionsInvestment}
                        className={styles.miniatureTable}
                      />
                  </div>
                  </div>
                
            <div className="row">
            {/* Area Chart */}
            {/* <div className="col-xl-12 col-lg-1"> */}
            <div className="col-xl-12 col-md-12 col-lg-12">
              {/* <div className="card shadow mb-4"> */}
              <div className="card shadow mb-4">
                {/* Card Header - Dropdown */}
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">{t("header.withdrawals")}</h6>

                </div>
                {/* Card Body */}
                <div>
                    <MUIDataTable
                        data={withdrawals}
                        columns={columnsWithdrawal} 
                        options={optionsWithdrawals}
                        className={styles.miniatureTable}
                        />
                  </div>
                </div>
                  </div>
                  </div>
                  </div>
                  </div>
              </div>
            </div>
          
      {/* End of Main Content */}
      {/* Footer */}
      <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto">
            <span>Copyright © Your Website 2024</span>
          </div>
        </div>
      </footer>
      {/* End of Footer */}
    </div>
    {/* End of Content Wrapper */}
    
  </div>
  {/* End of Page Wrapper */}
  </div>
  
  {/* Scroll to Top Button*/}
  <a className="scroll-to-top rounded" href="#page-top">
    <i className="fas fa-angle-up" />
  </a>

  <div className="modal fade" id="logoutModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">{t("footer.ready")}</h5>
          <button className="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">{t("footer.select")}</div>
        <div className="modal-footer">
          <button  className="btn btn-secondary" type="button" data-dismiss="modal">{t("footer.cancel")}</button>
          <a className="btn btn-primary" onClick={handleLogout} href='https://mexicolindoyqueridoproduce.com/'>{t("navbar.logout")}</a>
        </div>
      </div>
    </div>
  </div>
  </div>
  ) 
}


export default Body;

