import React, { useState } from "react";
import { PiQuestionDuotone } from "react-icons/pi";

const IconRegister = ({ content }) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleMouseEnter = () => {
    setShowInfo(true);
  };

  const handleMouseLeave = () => {
    setShowInfo(false);
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <PiQuestionDuotone
        size={20} 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ cursor: "pointer" }}
      />
      {showInfo && (
        <div
          style={{
            position: "absolute",
            transform: "translateX(-80%)",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "5px",
            boxShadow: "0 5px 5px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
            fontSize: "14px",
            minWidth: "200px",  
            wordWrap: "break-word", 
            overflow: "hidden",
          }}
        >
         
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export default IconRegister;
