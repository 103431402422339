import React, { useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleLeft } from "react-icons/fa";
import { API1 } from '../constants/api';
import { encodedCredentials50 } from '../constants/credentials';
import IconRegister from '../Iconos/IconRegister';
import { useTranslation } from 'react-i18next';
import { MdGTranslate } from "react-icons/md";

const Login = () => {
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();
  
  const [user, setUser] = useState(localStorage.getItem("userNameInput"));
  const [pass, setPass] = useState("");
  const [error, setError] = useState(false);

  const [isLoading, setIsLoading] = useState(false); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); 

  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || i18n.language 
  );

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
    i18n.changeLanguage(selectedLanguage); 
  }, [selectedLanguage]); 

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
      if (user === "" || pass === "") {
        setError(true)
        return
      }
      setError(false);

      setIsLoading(true);
      setIsButtonDisabled(true);
  
    const data = {
        "user": user,
        "pass": pass,
    };

      axios.post(`${API1}login`, data, {
      headers: {
        'Authorization': `Basic ${encodedCredentials50}`,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      
      if (response.data.message === "Successful login.") {
        if (isChecked  === true){
          
          localStorage.setItem('userNameInput', user);
        }
        localStorage.removeItem('user');
        setIsLoading(false);
        setIsButtonDisabled(false);
        localStorage.setItem('user', JSON.stringify(user));
        Swal.fire({
          title: selectedLanguage === 'en' ? 'Success' : 'Éxito',
          text: selectedLanguage === 'en' ? 'The operation was successful.' : 'La operación fue exitosa.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/index");
            window.location.reload();
          }
        });
      }
      
    })
    .catch((error) => {
      if (error.response.data.error === "Invalid username or password."  ) {

        Swal.fire({
        title: 'Error',
        text: selectedLanguage === 'en' ? 'Invalid username or password.' : 'Usuario y/o Contraseña inválido.',
        icon: 'error',
        confirmButtonText: 'OK'
        });
      }

      if (error.response.data.error === "Internal error. Contact support.") {
        console.error('Error:', error.response.data.error);
        Swal.fire({
          title: 'Error',
          text: selectedLanguage === 'en' ? 'There was an error processing the request.' : 'Hubo un error en el proceso.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
      setIsLoading(false);
      setIsButtonDisabled(false);
    });
    
  } 
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    
  };


  return (
    <div>
<div className="container">
<div className='nav-item dropdown d-flex  justify-content-between'>
      <a href="https://mexicolindoyqueridoproduce.com/">
        <FaArrowCircleLeft  style={{ fontSize: '20px', color: 'blue' }} />
        {t("register.home")}
        </a>
        <a className="nav-link dropdown-toggle" href="#" id="translate" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <MdGTranslate style={{ fontSize: '20px', color: 'blue' }} />
          </a>
          <div className="dropdown-menu dropdown-menu-right shadow" aria-labelledby="userDropdown">
                            <div className="d-sm-block">
                                <button className="dropdown-item  btn btn-sm" onClick={() => handleLanguageChange("es")}>Español</button>
                            </div>
                            <div className="d-sm-block">
                                <button className="dropdown-item btn btn-sm" onClick={() => handleLanguageChange("en")}>English</button>
                            </div>
                    </div>
              </div>
  {/* Outer Row */}
  <div className="row justify-content-center">
    
    <div className="col-xl-10 col-lg-12 col-md-9">
      <div className="card o-hidden border-0 shadow-lg my-5">
        
        <div className="card-body p-0">
          {/* Nested Row within Card Body */}
          <div className="row">
            <div className="col-lg-6 d-none d-lg-block">
            <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{width: '40rem', height: '26rem'}} src="img/login.jpg" alt="forgot"></img>
          </div>
            <div className="col-lg-6">
              <div className="p-5">
                <div className="text-center">
                  <h1 className="h4 text-gray-900 mb-4">{t("login.welcomeL")}</h1>
                </div>
                <form className="user" >
                  <div className="form-group">
                    <input type="text"
                     className="form-control form-control-user"
                      id="exampleInputEmail"  
                      placeholder={t("login.user")}
                      value={user} onChange={(e) => setUser(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <input type="password"
                     className="form-control form-control-user"
                     id="exampleInputPassword"
                     placeholder={t("login.password")} 
                     value={pass} onChange={(e) => setPass(e.target.value)}/>
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox small">
                      <input type="checkbox" className="custom-control-input" id="customCheck"
                      checked={isChecked}
                      onChange={handleCheckboxChange}/>
                      <label className="custom-control-label" htmlFor="customCheck">{t("login.remember")} </label>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary btn-user btn-block"disabled={isButtonDisabled} onClick={handleSubmit} 
                     >
                       {isLoading ? 'Loading...' : t("login.loginL")} 
                  </button>
                  <hr />
                </form>
                {error && <p>All fields are required</p>}
                <hr />
                <div className="text-center">
                  <a className="small" href="#forgot-password">{t("login.forgot")}</a>
                </div>
                <br/>
                <div className="text-center">
                  <a className="small" href="#register">{t("login.loginRegister")}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Login;