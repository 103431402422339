import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import styles from "./Register.module.css";
import axios from 'axios';
import { FaArrowCircleLeft } from "react-icons/fa";
import { API2 } from '../constants/api';
import { encodedCredentials51 } from '../constants/credentials';
import { useTranslation } from 'react-i18next';
import { MdGTranslate } from "react-icons/md";
import IconRegister from '../Iconos/IconRegister';

const Register = () => {
 
  const [isLoading, setIsLoading] = useState(false); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); 
  // const [file, setFile] = useState("");
  const fechaActual = new Date();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [regionTel, setRegionTel] = useState("+52");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [user, setUser] = useState("");
  const [reference, setReference] = useState("");
  const [pass, setPass] = useState("");
  const [passRepeat, setPassRepeat] = useState("");
  // const [userModify, setUserModify] = useState("");
  const [selectedFileNamee, setSelectedFileNamee] = useState('Select file:');
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState(0);
  const [gender, setGender] = useState("Man");
  const [country, setCountry] = useState("MEX");
  // const [cameraStream, setCameraStream] = useState("");
  // const [errorMessage2, setErrorMessage2] = useState('');
  const fileInputRef = useRef(""); 


  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || i18n.language 
  );

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
    i18n.changeLanguage(selectedLanguage); 
  }, [selectedLanguage]); 

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!name || !lastName || !email || !tel || !birthDate || !user || !pass) {
      setErrorMessage(t("register.complete"));
      return;
    }
    if (pass.length < 8) {
      setErrorMessage(t("register.errorPassword"));
     } else {
    setErrorMessage(""); 
    }
    if (user.length < 6) {
      setErrorMessage(t("register.errorUsuario"));
     } else {
    setErrorMessage(""); 
    }
    if (!/(?=.*[A-Z])(?=.*\d)/.test(pass)) {
      setErrorMessage(t("register.errorPassword2"));
      } else {
    setErrorMessage(""); 
    }
    if (!/(?=.*[A-Z])(?=.*\d)/.test(user)) {
      setErrorMessage(t("register.errorUsuario2"));
       } else {
    setErrorMessage(""); 
    }
    if (pass !== passRepeat){
      setErrorMessage(
        t("register.errorPassword3"));
     } else {
    setErrorMessage(""); 
    }
    if (pass === user) {
      setErrorMessage(t("register.errorUsuario3"));
      return;
    } else {
      setErrorMessage(""); 
    }

    setIsLoading(true);
    setIsButtonDisabled(true);


    const formData = new FormData(); 
    const data = {
      name: name,
      last_name: lastName,
      gender : gender,
      region_tel: regionTel,
      tel: tel,
      email: email,
      birth_date: birthDate,
      user: user,
      pass: pass,
      country : country,
      reference: reference,
      create_date: fechaActual,
      flag_evidence: value,
      user_modify: "pagina web",
    };
    

    const fileInput = document.getElementById('fileInput');
    const file = fileInput.files[0];
     
    if (!file) {
      Swal.fire({
        title: 'Error',
        text: selectedLanguage === 'en' ? 'The file is missing as an attachment.' : 'No se pudo encontrar el archivo seleccionado.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return; 
    }

    
    formData.append('file', file, file.name);
    formData.append('data', new Blob([JSON.stringify(data)], { type: 'application/json' }));

   {
    const response = await axios.post(`${API2}tempuser`, formData, {
      headers: {
        'Authorization': `Basic ${encodedCredentials51}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      if (response.data){
        const dataemail = {
          "to": email,
          "subject": "prueba de correo electrónico2",
          "name": name,
        }
        
      {
        axios.post(`${API2}send-email`, dataemail, {
         headers: {
           'Authorization': `Basic ${encodedCredentials51}`,
           'Content-Type': 'application/json'
         }
       })
       .then((response) => {
        
         })
         .catch((error) => {
           console.error('Error:', error.response);
           Swal.fire({
             title: 'Error',
             text: selectedLanguage === 'en' ? 'There was an error processing the request.' : 'Hubo un error en el proceso.',
             icon: 'error',
             confirmButtonText: 'OK'
           });
         });
        }
     };
        
        Swal.fire({
          title: selectedLanguage === 'en' ? 'Success' : 'Éxito',
          text: selectedLanguage === 'en' ? 'Your data will be evaluated, please check your email.' : 'Tus datos serán evaluados, por favor checa tu correo.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = 'https://mexicolindoyqueridoproduce.com/';
          }
        
        setIsLoading(false);
        setIsButtonDisabled(false);
      })
    })
    .catch((error) => {
          //   Swal.fire({
          //   title: 'Error',
          //   text: selectedLanguage === 'en' ? 'The user or email already exists.' : 'El usuario o email ya existe.',
          //   icon: 'error',
          //   confirmButtonText: 'OK'
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     window.location.reload();
          //   }
          //   setIsLoading(false);
          //   setIsButtonDisabled(false);
          // });
      if (error) {
        const errorMessage = error.response.data.error;
        // console.error( error.response.data.error);
        if (errorMessage ==='The user or email already exists') {
          Swal.fire({
            title: 'Error',
            text: selectedLanguage === 'en' ? 'The user or email already exists.' : 'El usuario o email ya existe.',
            icon: 'error',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
            setIsLoading(false);
            setIsButtonDisabled(false);
          });
        } else if (errorMessage === 'An error occurred while processing the request') {
          Swal.fire({
            title: 'Error',
            text: selectedLanguage === 'en' ? 'An error occurred while processing the request. Please try again.' : 'Hubo un error en el proceso. Por favor, intenta de nuevo.',
            icon: 'error',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
            setIsLoading(false);
            setIsButtonDisabled(false);
          });
        }
      } else {
        Swal.fire({
          title: 'Error',
          text: selectedLanguage === 'en' ? 'Something went wrong. Please try again.' : 'Algo salió mal. Por favor, intenta de nuevo.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        setIsLoading(false);
        setIsButtonDisabled(false);
      }
    });
    
        

    
  };
}

  // const handleFileChange = async (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //   setSelectedFileNamee("Selected file: " + file.name);
  //   }

  //   await requestCameraAccess();
  // };

  // const requestCameraAccess = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //     setCameraStream(stream);
  //   } catch (error) {
  //     setErrorMessage2('No se pudo acceder a la cámara: ' + error.message);
  //   }
  // };

  // const stopCameraAccess = () => {
  //   if (cameraStream) {
  //     cameraStream.getTracks().forEach((track) => track.stop());
  //     setCameraStream(null);
  //   }
  // };

  // const handleClick = async () => {
   
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //     setCameraStream(stream);
      
  //     fileInputRef.current.click();
  //   } catch (error) {
  //     setErrorMessage2("No se pudo acceder a la cámara: " + error.message);
  //   }
  // };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFileNamee("Selected file: " + file.name);
    }
  };



  return (
    <div>
      < div className="container">
      
        <div className='nav-item dropdown d-flex  justify-content-between'>
      <a href="https://mexicolindoyqueridoproduce.com/">
        <FaArrowCircleLeft  style={{ fontSize: '20px', color: 'blue' }} />
        {t("register.home")}
        </a>
        <a className="nav-link dropdown-toggle" href="#" id="translate" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <MdGTranslate style={{ fontSize: '20px', color: 'blue' }} />
          </a>
          <div className="dropdown-menu dropdown-menu-right shadow" aria-labelledby="userDropdown">
                            <div className="d-sm-block">
                                <button className="dropdown-item btn btn-sm" onClick={() => handleLanguageChange("es")}>Español</button>
                            </div>
                            <div className="d-sm-block">
                                <button className="dropdown-item btn btn-sm" onClick={() => handleLanguageChange("en")}>English</button>
                            </div>
                    </div>
              </div>
              
        <div className="card o-hidden border-0  shadow-lg my-5">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-6 d-none d-lg-block">
                <img className="img-fluid px-3 px-sm-4 mt-3" style={{ width: '35rem', height: '35rem' }} src="img/register.jpg" alt="forgot"></img>
              </div>

              <div className="col-lg-6">
                <div className="p-3">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">{t("register.createAn")}</h1>
                  </div>
                  <form className="user" onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0 d-flex justify-content-between">
                        <input type="text" className="form-control form-control-user" id="exampleFirstName" placeholder={t("register.name")}
                          value={name} onChange={(e) => setName(e.target.value)} />
                                           <div >
                      <span>
                          <IconRegister
                          className=''
                          content={t("register.inputName")}
                          />
                            </span>
                          </div>
                      </div>
                      <div className="col-sm-6 d-flex justify-content-between">
                        <input type="text" className="form-control form-control-user" id="exampleLastName" placeholder={t("register.lastname")}
                          value={lastName} onChange={(e) => setLastName(e.target.value)} />
                      <div >
                      <span>
                          <IconRegister
                          content={t("register.inputLast")}
                          />
                      </span>
                      </div>
                      </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-12 mb-3 mb-sm-0  d-flex justify-content-between">
                      <input type="email" required className="form-control form-control-user" id="exampleInputEmail" placeholder={t("register.email")}
                        value={email} onChange={(e) => setEmail(e.target.value)} />
                                               <div >
                      <span>
                          <IconRegister
                          content={t("register.inputEmail")}
                          />
                      </span>
                      </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0  d-flex justify-content-between">
                      <select className={styles.formControl} name="gender"
                          value={gender} onChange={(e) => setGender(e.target.value)}>
                          <option value="Man">{t("register.man")}</option>
                          <option value="Woman">{t("register.woman")}</option>
                        </select>
                        <div >
                      <span>
                          <IconRegister
                          content={t("register.inputGender")}
                          />
                      </span>
                      </div>
                      </div>
                      <div className="col-sm-6  d-flex justify-content-between">
                        <select type="text" className={styles.formControl} id="exampleCountry"
                          value={country} onChange={(e) => setCountry(e.target.value)} >
                          <option value="MEX">México</option>
                          <option value="USA">United States(USA)</option>
                         </select>
                         <div >
                      <span>
                          <IconRegister
                          content={t("register.inputPais")}
                          />
                      </span>
                      </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-3 mb-3 mb-sm-0  d-flex justify-content-between">
                        <select className={styles.formControl} name="regionTel"
                          value={regionTel} onChange={(e) => setRegionTel(e.target.value)}>
                          <option value="+52">(+52)</option>
                          <option value="+1">(+1)</option>
                        </select>
                        <div >
                      <span>
                          <IconRegister
                          content={t("register.inputLada")}
                          />
                      </span>
                      </div>
                      </div>
                      <div className="col-sm-9 mb-3 mb-sm-0  d-flex justify-content-between">
                        <input type="text" className="form-control form-control-user" id="examplePhoneNumber" placeholder={t("register.phone")}
                          value={tel} onChange={(e) => setTel(e.target.value)} />
                       <div >
                      <span>
                          <IconRegister
                          content={t("register.inputTel")}
                          />
                      </span>
                      </div>
                      </div>
 
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0  d-flex justify-content-between">
                        <h6 className="h6 text-gray-900">{t("register.birthdate")}:</h6>
                        <input type="date" className="form-control form-control-user" id="exampleBirthdate"
                          value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
                          <div >
                      <span>
                          <IconRegister
                          content={t("register.inputFecha")}
                          />
                      </span>
                      </div>
                      </div>
                      <div className="col-sm-6  d-flex justify-content-between">
                        <p className="h6 text-gray-900">{t("register.evidence")}:</p>
                        <label htmlFor="fileInput" className="btn btn-primary btn-user btn-block" >
                        {/* <button  style={{ visibility: "hidden" }} onClick={handleClick}></button> */}
                         {/* <input type="file" id="fileInput"  accept="image/*,.pdf,.doc,.docx,.txt" className="d-none" onChange={handleFileChange}/> */}
                        {/* <input type='hidden' onClick={handleClick} /> */}
                          <input
                           type="file" 
                           id="fileInput" 
                           className="d-none" 
                           capture="environment"  
                           accept="image/*,.pdf,.doc,.docx,.txt"  
                           name='fileInput'
                           ref={fileInputRef} 
                           onClick={handleClick} 
                           onChange={handleFileChange}/>

                          {selectedFileNamee && <span>{selectedFileNamee}</span>}
                        </label>
                        <div >
                      <span>
                          <IconRegister
                          content={t("register.inputInvestment")}
                          />
                      </span>
                      </div>
                      </div>
                    </div>
                    <hr />
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0  d-flex justify-content-between">
                        <input type="text" className="form-control form-control-user" id="exampleUser" placeholder={t("register.user")}
                          value={user} onChange={(e) => setUser(e.target.value)} />
                          <div >
                      <span>
                          <IconRegister
                          content={t("register.inputUser")}
                          />
                      </span>
                      </div>
                      </div>
                      
                      <div className="col-sm-6  d-flex justify-content-between">
                        <input type="text" className="form-control form-control-user" id="exampleReference"
                          placeholder={t("register.advisor")}
                          value={reference} onChange={(e) => setReference(e.target.value)} />
                          <div >
                      <span>
                          <IconRegister
                          content={t("register.inputAdvisor")}
                          />
                      </span>
                      </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0  d-flex justify-content-between">
                        <input type="password" className="form-control form-control-user" placeholder={t("register.password")} name="pass"
                          value={pass} onChange={(e) => setPass(e.target.value)} />
                          <div >
                      <span>
                          <IconRegister
                          content={t("register.inputPass")}
                          />
                      </span>
                      </div>
                      </div>
                      <div className="col-sm-6 mb-3 mb-sm-0  d-flex justify-content-between">
                        <input type="password" className="form-control form-control-user" placeholder={t("register.repeatP")} name="repeatPass"
                        value={passRepeat} onChange={(e) => setPassRepeat(e.target.value)}/>
                        <div >
                      <span>
                          <IconRegister
                          content={t("register.inputPass2")}
                          />
                      </span>
                      </div>
                      </div>
                    </div>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <button type="submit" className="btn btn-primary btn-user btn-block"
                     disabled={isButtonDisabled} 
                     >
                       {isLoading ? 'Loading...' : t("register.registerA")} 
                    
                    </button>
                  </form>
                  <hr />
                  <div className="text-center">
                    <a className="small" href="#forgot-password">{t("register.forgot")}</a>
                  </div>
                  <br/>
                  <div className="text-center">
                    <a className="small" href="#login">{t("register.login")}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;